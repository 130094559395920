import { Box, Button, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import Constant from "../../../utills/Constant";
import dummyUser from "../../../Assets/Images/avtar.webp";
import EditIcon from "../../../Assets/Images/edit.png";
import deleteIcon from "../../../Assets/Images/delete.png";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const AffirmativeTipsManagement = () => {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [affirmationList, setAffirmationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const getAffirmationList = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(ApiList.GET_AFFIRMATION, {
        params: { is_admin: 1, count: page * pageSize, pageSize: pageSize },
      });
      if (response && response?.data?.s) {
        const Rowdata = response.data.r.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setAffirmationList(Rowdata);
        setCount(response?.data?.c);
      } else {
        setAffirmationList([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    getAffirmationList();
  }, [page]);

  const handleDeleteVideo = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this affirmation",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          id: id,
          status: "0",
        };
        try {
          const response = await AxiosInstance.post(
            ApiList.EDIT_DELETE_AFFIRMNATION,
            params
          );
          if (response?.data?.s === 1) {
            toast.success("Affirmation Deleted Successfully!");
            getAffirmationList();
          } else {
            toast.error("Failed to delete affirmation. Please try again.");
          }
        } catch (error) {
          toast.error("Failed to delete affirmation. Please try again.");
        }
      }
    });
  };

  const columns = [
    {
      field: "rowid",
      headerName: "#",
      width: 50,
      renderCell: (params) => (params?.row?.rowid ? params?.row?.rowid : "--"),
    },
    {
      field: "thumb",
      headerName: "Image",
      width: 80,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "15px",
          }}
        >
          <img
            src={
              params?.row?.thumb
                ? `${Constant.BASE_URL}${params?.row?.thumb}`
                : dummyUser
            }
            alt="thumb"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
          />
        </Box>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => (params?.row?.title ? params?.row?.title : "--"),
    },
    {
      field: "description",
      flex: 1,
      headerName: "Description",
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        const description = params?.row?.description || "--";
        const truncatedLink =
          description.length > 45
            ? `${description.substring(0, 45)}...`
            : description;

        return (
          <Tooltip title={description}>
            <span>{truncatedLink}</span>
          </Tooltip>
        );
      },
      // renderCell: (params) =>
      //   params?.row?.description ? params?.row?.description : "--",
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 150,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "15px",
          }}
        >
          <img
            src={EditIcon}
            alt="editIcon"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() =>
              navigate("/admin/edit-affirmative-tips", {
                state: {
                  affirmativeID: params?.row?.id,
                  type: "Edit",
                },
              })
            }
          />
          <img
            src={deleteIcon}
            alt="delete"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() => handleDeleteVideo(params?.row?.id)}
          />
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ width: "100%", padding: "15px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ fontSize: { xs: "22px", md: "32px" }, fontWeight: "500" }}
          >
            Daily Divine Health Affirmations
          </Typography>
          <Button
            onClick={() => navigate("/admin/add-affirmative-tips")}
            sx={{
              background: "#000E72",
              color: "white",
              "&:hover": { background: "#000E72", color: "white" },
              textTransform: "none",
            }}
          >
            + Add Affirmation
          </Button>
        </Box>
      </Box>
      <hr style={{ marginTop: "20px" }} />
      <Box sx={{ padding: "24px 0px 24px 0px" }}>
        <TableContainer
          loading={isLoading}
          rows={affirmationList}
          columns={columns}
          pageSize={pageSize}
          page={page}
          totalCount={count}
          handlePageChange={handlePageChange}
        />
      </Box>
    </Box>
  );
};

export default AffirmativeTipsManagement;
