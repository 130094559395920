import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Modal,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import Constant from "../../../utills/Constant";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import { toast } from "react-toastify";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";

const AddSubAdmin = (props) => {
  const { open, onClose, handleOnSubmit, userID, typeCheck, singleDetails } =
    props;
  const [isLoading, setIsLoading] = useState(false);
  const [subRightsList, setSubRightList] = useState([]);

  const [checkedRights, setCheckedRights] = useState({});
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userName: typeCheck === "Edit" ? singleDetails?.user_name : "",
      email: (singleDetails && singleDetails?.email) || "",
      password: "",
      Cpassword: "",
    },
    validationSchema: Yup.object().shape({
      userName: Yup.string().required("User name is required"),
      email: Yup.string()
        .required("Email Address is required")
        .matches(Constant.REGEX.EMAIL, "Please Enter Valid Email Address"),
      ...(typeCheck !== "Edit" && {
        password: Yup.string().required("Password is required"),
        Cpassword: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required"),
      }),
    }),

    onSubmit: async (values, { resetForm }) => {
      console.log("values", values);
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("user_name", values.userName);
        formData.append("email", values.email);
        if (typeCheck === "Edit") {
          formData.append("id", singleDetails?.id);
        }
        if (typeCheck !== "Edit" && values.password) {
          formData.append("password", values.password);
        }

        const rights = Object.keys(checkedRights)
          .filter((key) => checkedRights[key])
          .map((key) => key.split("-")[1]);
        formData.append("previleges", rights.join(","));

        let response;
        if (typeCheck === "Edit") {
          response = await AxiosInstance.post(ApiList.EDIT_SUBADMIN, formData);
        } else {
          response = await AxiosInstance.post(ApiList.ADD_SUBADMIN, formData);
        }

        if (response && response.data.s) {
          toast.success(
            typeCheck === "Edit"
              ? "SubAdmin Updated Successfully."
              : "SubAdmin Added Successfully."
          );
          resetForm();
          handleOnSubmit();
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  });
console.log(formik?.values?.userName)
  const getPrivilages = async (userID) => {
    try {
      const response = await AxiosInstance.get(ApiList.GET_ALL_PREVELIGES, {
        params: { user_id: userID },
      });
      if (response && response.data.s) {
        setSubRightList(response.data.r);
        const initialCheckedRights = {};

        // Create an array from the privileges string
        const privilegesArray = singleDetails?.previleges?.split(",") || [];

        response.data.r.forEach((right) => {
          // Check if the right's ID exists in the privileges array
          initialCheckedRights[`right-${right.id}`] = privilegesArray.includes(
            right.id.toString()
          );
        });

        setCheckedRights(initialCheckedRights);
      } else {
        setSubRightList([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (typeCheck === "Edit") {
      getPrivilages(singleDetails?.id);
    } else {
      getPrivilages(0);
    }
  }, [typeCheck, singleDetails]);

  const handleCheckboxChange = (id) => {
    setCheckedRights((prev) => ({
      ...prev,
      [`right-${id}`]: !prev[`right-${id}`],
    }));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        className="addsubadmin"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "40px",
          height: "500px",
          overflow: "scroll",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: "500" }}>
            {typeCheck === "Edit" ? "Update Sub-Admin" : "Add Sub-Admin"}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
              gap: "20px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <CustomTextField
                  label="User Name"
                  type="text"
                  placeholder="Enter User Name"
                  name="userName"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errors={formik.touched.userName && formik.errors.userName}
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#F0F0F0",
                      borderRadius: "10px",
                    },
                  }}
                />
                <CustomTextField
                  label="Email"
                  type="text"
                  placeholder="Enter Email"
                  name="email"
                  disabled={typeCheck === "Edit"}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errors={formik.touched.email && formik.errors.email}
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#F0F0F0",
                      borderRadius: "10px",
                    },
                  }}
                />
                <CustomTextField
                  label="Password"
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errors={formik.touched.password && formik.errors.password}
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#F0F0F0",
                      borderRadius: "10px",
                      display: typeCheck === "Edit" ? "none" : "",
                    },
                  }}
                  style={{ display: typeCheck === "Edit" ? "none" : "" }}
                />
                <CustomTextField
                  label="Confirm Password"
                  type="password"
                  placeholder="Enter Confirm Password"
                  name="Cpassword"
                  value={formik.values.Cpassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errors={formik.touched.Cpassword && formik.errors.Cpassword}
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#F0F0F0",
                      borderRadius: "10px",
                      display: typeCheck === "Edit" ? "none" : "",
                    },
                  }}
                  style={{ display: typeCheck === "Edit" ? "none" : "" }}
                />
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Grid container spacing={3}>
                {subRightsList?.map((elem, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={index}
                    sx={{ display: "flex", justifyContent: "start" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ color: "black" }}
                          checked={checkedRights[`right-${elem.id}`] || false}
                          onChange={() => handleCheckboxChange(elem.id)}
                        />
                      }
                      label={elem.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <CustomLoaderButton
              btnTitle={
                typeCheck === "Edit" ? "Update Sub-Admin" : "Add Sub-Admin"
              }
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: "#000E72",
                color: "white",
                px: 2,
                width: "350px",
                fontSize: "14px",
              }}
            />
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddSubAdmin;
