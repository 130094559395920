import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomThumbUpload from "../../../Components/CustomFileUpload/CustomThumbUpload";
import { toast } from "react-toastify";
import CustomDatePicker from "../../../Components/CustomDatePicker/CustomDatePicker";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import moment from "moment";
import Constant from "../../../utills/Constant";
import Skeleton from "react-loading-skeleton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const AddMarchendise = () => {
  const location = useLocation();
  const [selectedImagePrev, setSelectedImagePrev] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editMarnchendise, setEditMarchendise] = useState({});
  const [typeCheck, setTypeCheck] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (location?.state?.resourceData) {
      setEditMarchendise(location?.state?.resourceData);
      setTypeCheck(location?.state?.type);
      if (editMarnchendise?.thumb) {
        setSelectedImagePrev(`
https://dev-api.healinginstitute.org/${editMarnchendise?.thumb}`);
      }
    }
  }, [typeCheck, location?.state?.resourceData]);

  const handleOnChangeImages = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.error("Invalid file type for thumb!");
    } else {
      const file = acceptedFiles[0];
      if (
        file &&
        ["image/jpeg", "image/png", "image/gif"].includes(file.type)
      ) {
        if (file.size <= 2 * 1024 * 1024) {
          const videoUrl = URL.createObjectURL(file);
          setSelectedImageFile(file);
          setSelectedImagePrev(videoUrl);
          formik.setFieldValue("thumb", file);
        } else {
          toast.error(`File size exceeds the limit of 2MB`);
        }
      } else {
        toast.error("Upload only (PNG,JPEG,WEBP,JPG)!");
      }
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      thumb: (typeCheck && editMarnchendise?.thumb) || null,
      productName: (typeCheck && editMarnchendise?.name) || "",
      productPrice: (typeCheck && editMarnchendise?.price) || "",
      productLink: (typeCheck && editMarnchendise?.link) || "",
      addDescription: (typeCheck && editMarnchendise?.description) || "",
      scheduleSelected:
        typeCheck && editMarnchendise?.schedule_date !== null ? "2" : "1",
      timeSelected:
        typeCheck && editMarnchendise?.schedule_date
          ? dayjs(editMarnchendise?.schedule_date)
          : null,
    },
    validationSchema: Yup.object().shape({
      thumb: Yup.mixed().required("This field is required."),
      productName: Yup.string().required("This field can't be empty!"),
      productPrice: Yup.string().required("This field can't be empty!"),
      productLink: Yup.string()
        .required("This field can't be empty!")
        .matches(Constant.REGEX.WEBSITEURL, "Please provide a valid url."),
      addDescription: Yup.string().required("This field can't be empty!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        setIsLoading(false);
        return;
      }

      const formData = new FormData();
      if (typeCheck === "Edit") {
        formData.append("id", editMarnchendise.id);
      }
      if (selectedImageFile) {
        formData.append("thumb", selectedImageFile);
      }
      formData.append("name", values.productName);
      formData.append("price", values.productPrice);
      formData.append("link", values.productLink);
      formData.append("description", values.addDescription);

      if (values.scheduleSelected === "2") {
        const scheduleDate = moment(values.timeSelected?.$d)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
        formData.append("schedule_date", scheduleDate);
      }

      try {
        const apiEndpoint =
          typeCheck === "Edit"
            ? ApiList.DELETE_RESOURCES
            : ApiList.ADD_RESOURCES;
        const response = await AxiosInstance.post(apiEndpoint, formData);

        if (response && response.data?.s) {
          toast.success(response.data.m);
          resetForm();
          setSelectedImageFile(null);
          setSelectedImagePrev(null);
          setIsLoading(false);
          navigate(-1);
        }
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    },
  });
  return (
    <>
      <Box sx={{ width: "100%", px: 5, py: 3 }}>
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            top: "0",
            left: "0",
            p: 2,
            width: "calc(100%)",
            alignItems: "center",
            bgcolor: "white",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            zIndex: "2",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              style={{
                cursor: "pointer",
                fontWeight: "500",
                fontSize: "22px",
                textDecoration: "none",
                color: "black",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                border: "1px solid #0000003D",
              }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{ fontSize: "32px", fontWeight: "500", textAlign: "center" }}
            >
              {typeCheck ? "Update" : "Add"} Marchendise
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}></Box>
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
          style={{
            paddingTop: "100px",
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: "100%", md: "90%", lg: "80%", xl: "70%" },
              margin: "0 auto",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CustomThumbUpload
                    accept="image/*"
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      handleOnChangeImages(acceptedFiles, rejectedFiles)
                    }
                    selectedFile={selectedImagePrev}
                    onRemove={(e) => {
                      e.stopPropagation();
                      setSelectedImagePrev(null);
                      setSelectedImageFile(null);
                      formik.setFieldValue("thumb", null);
                    }}
                    typeCheck={typeCheck}
                  />
                  {formik.touched.thumb && formik.errors.thumb ? (
                    <Typography
                      sx={{
                        color: "red",
                        fontWeight: "400",
                        fontSize: { xs: "14px", sm: "14px" },
                        marginTop: "5px",
                      }}
                    >
                      {formik.errors.thumb}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <RadioGroup
                          value={formik.values.scheduleSelected}
                          name="scheduleSelected"
                          onChange={formik.handleChange}
                          sx={{
                            width: "100%",
                          }}
                        >
                          {isLoading ? (
                            <Skeleton height={"50px"} />
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <FormControlLabel
                                value={"1"}
                                control={<Radio />}
                                label="Publish Now"
                              />
                              <FormControlLabel
                                value={"2"}
                                control={<Radio />}
                                label="Schedule Later"
                              />
                            </Box>
                          )}
                        </RadioGroup>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {formik.values.scheduleSelected === "2" && (
                    <CustomDatePicker
                      value={formik.values.timeSelected}
                      onChange={(newValue) =>
                        formik.setFieldValue("timeSelected", newValue)
                      }
                      error={
                        formik.touched.timeSelected &&
                        formik.errors.timeSelected
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <CustomTextField
                    label="Product Name"
                    type="text"
                    placeholder="Product Name"
                    name="productName"
                    value={formik.values.productName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onKeyUp={formik.handleBlur}
                    errors={
                      formik.touched.productName && formik.errors.productName
                    }
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#F0F0F0",
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <CustomTextField
                    label="Product Price"
                    placeholder="Product Price"
                    name="productPrice"
                    value={formik.values.productPrice}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== "" && value.startsWith("0")) {
                        formik.setFieldValue(
                          "productPrice",
                          value.replace(/^0+/, "")
                        );
                      } else {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (value !== "" && value.startsWith("0")) {
                        formik.setFieldValue("price", value.replace(/^0+/, ""));
                      }
                      formik.handleBlur(e);
                    }}
                    type="number"
                    min="1"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    errors={
                      formik.touched.productPrice && formik.errors.productPrice
                    }
                    onKeyUp={formik.handleBlur}
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#F0F0F0",
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    label="Enter Product Link"
                    type="text"
                    placeholder="Product Link"
                    name="productLink"
                    value={formik.values.productLink}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onKeyUp={formik.handleBlur}
                    errors={
                      formik.touched.productLink && formik.errors.productLink
                    }
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#F0F0F0",
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    label="Add Description"
                    type="text"
                    placeholder="Description"
                    name="addDescription"
                    multiline
                    rows={5}
                    value={formik.values.addDescription}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errors={
                      formik.touched.addDescription &&
                      formik.errors.addDescription
                    }
                    onKeyUp={formik.handleBlur}
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#F0F0F0",
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      py: 3,
                      px: 4,
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <CustomLoaderButton
                      btnTitle={typeCheck ? "Update" : "Add"}
                      sx={{ width: "150px" }}
                      type="submit"
                      loading={isLoading}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AddMarchendise;
