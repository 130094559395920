import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import EDIT_ICON from "../../../Assets/Images/edit.png";
import DELETE_ICON from "../../../Assets/Images/delete.png";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import AddSubAdmin from "./AddSubAdmin";

export const SubAdmin = () => {
  const pageSize = 10;
  const [getSubAdmin, setGetSubAdmin] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [typeCheck, setTypeCheck] = useState(null);
  const [userID, setUserID] = useState(null);
  const [singleDetails, setSingleDetails] = useState({});
  const handleOpenModal = (ID) => {
    setOpenModal(true);
    // setTypeCheck(null);
    setUserID(ID)
    setTypeCheck("Add")
  };
  const handleEdit = (data) => {
    setOpenModal(true);
    setTypeCheck("Edit");
    setSingleDetails(data)
  };
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Sub-Admin",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          id: id,
          is_delete: 1,
        };
        try {
          const response = await AxiosInstance.post(
            ApiList.EDIT_SUBADMIN,
            params
          );
          if (response?.data?.s === 1) {
            getSubAdminList();
            toast.success("Sub-Admin Deleted Sucessfully !.");
          }
        } catch (error) {
          toast.error("Failed to delete Sub-Admin. Please try again.");
        }
      }
    });
  };
  const getSubAdminList = async () => {
    try {
      setIsLoading(true);
      const res = await AxiosInstance.get(ApiList.GET_SUBADMIN_LIST, {
        params: {
          count: page * pageSize,
          offset: pageSize,
        },
      });
      if (res && res?.data?.s === 1) {
        const Rowdata = res?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setGetSubAdmin(Rowdata);
        setCount(res?.data?.c || 0);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSubAdminList();
  }, [page]);
  const columns = [
    {
      field: "rowid",
      headerName: "#",
      width: 50,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => (params?.row?.email ? params?.row?.email : "--"),
    },
    {
      field: "user_name",
      headerName: "User Name",
      flex: 1,
      renderCell: (params) =>
        params?.row?.user_name ? params?.row?.user_name : "--",
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 250,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <IconButton onClick={() => handleEdit(params?.row)}>
            <img
              src={EDIT_ICON}
              alt="view"
              style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            />
          </IconButton>
          <IconButton onClick={() => handleDelete(params?.row?.id)}>
            <img
              src={DELETE_ICON}
              alt="reset"
              style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            />
          </IconButton>
        </Box>
      ),
    },
  ];
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleOnSubmit = () => {
    setOpenModal(false);
    setTypeCheck(null);
    setUserID(null)
    getSubAdminList();
  }
  return (
    <>
      <Box sx={{ width: "100%", padding: "15px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: { xs: "20px", md: "28px" },
                fontWeight: "500",
                paddingBottom: "10px",
              }}
            >
              Sub Admin
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{
                  background: "#000E72",
                  color: "white",
                  "&:hover": { background: "#000E72", color: "white" },
                  textTransform: "none",
                }}
                onClick={() => handleOpenModal(0)} 
              >
                + Add Sub-Admin
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            padding: "24px 0px 24px 0px",
            borderTop: "1px solid #0000003D",
          }}
        >
          <TableContainer
            rows={getSubAdmin}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            page={page}
            handlePageChange={handlePageChange}
            totalCount={count}
          />
        </Box>
      </Box>
      <AddSubAdmin
        onClose={() => {setOpenModal(false); setSingleDetails({})}}
        open={openModal}
        userID={userID}
        handleOnSubmit={handleOnSubmit}
        typeCheck={typeCheck}
        singleDetails={singleDetails}
      />
    </>
  );
};
