import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import deleteIcon from "../../../Assets/Images/delete.png";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import Constant from "../../../utills/Constant";
import AddGallery from './AddGallery';

const GalleryManagement = () => {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [addGalleryModel, setAddGalleryModel] = useState(false);
  const [galleryList, setGalleryList] = useState([]);

  const getGalleryData = async () => {
    setIsLoading(true);
    try {
      const params = {
        count: page * pageSize,
        is_admin: 1,
      };
      const response = await AxiosInstance.get(ApiList.GET_GALLERY, { params });
      if (response && response.data.s) {
        const Rowdata = response.data.r.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setGalleryList(Rowdata);
        setCount(response.data.c);
      } else {
        setGalleryList([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGalleryData();
  }, [page]);

  const columns = [
    { field: "rowid", headerName: "#", width: 50 },
    {
      field: "img",
      headerName: "Images",
      width: 200,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "15px",
          }}
        >
          <img
            src={`${Constant.BASE_URL}${params?.row?.img}`}
            alt="delete"
            style={{ width: "40px", height: "40px", borderRadius: "5px" }}
          />
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Category",
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (params?.row?.name ? params?.row?.name : "--"),
    },
    {
      field: "action",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "15px",
          }}
        >
          <img
            src={deleteIcon}
            alt="delete"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() => handleDeleteGallery(params?.row?.id)}
          />
        </Box>
      ),
    },
  ];

  const handleOnAddGalleryModel = () => {
    setAddGalleryModel(true);
  };

  const handleDeleteGallery = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this gallery",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = { id, status: "0" };
        try {
          const res = await AxiosInstance.post(ApiList.DELETE_GALLERY, params);
          if (res?.data?.s === 1) {
            toast.success("Deleted Successfully");
            getGalleryData(); // Refresh the gallery list after deletion
          }
        } catch (error) {
          toast.error("Failed to delete gallery. Please try again.");
          console.log(error);
        }
      }
    });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Box sx={{ width: "100%", padding: '15px' }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: { xs: "20px", md: "28px" }, fontWeight: "500", paddingBottom: '10px' }}>
              Gallery Management
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}>
              <Button
                sx={{
                  background: "#000E72",
                  color: "white",
                  "&:hover": { background: "#000E72", color: "white" },
                  textTransform: "none",
                }}
                onClick={handleOnAddGalleryModel}
              >
                + Add Gallery
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ padding: "24px 0px 0px 0px", borderTop: '1px solid #0000003D' }}>
          <TableContainer
            rows={galleryList}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            page={page}
            handlePageChange={handlePageChange}
            totalCount={count}
          />
        </Box>
      </Box>
      <AddGallery
        open={addGalleryModel}
        onClose={() => setAddGalleryModel(false)}
        close={() => setAddGalleryModel(false)}
        handleSubmit={() => {
          setAddGalleryModel(false);
          getGalleryData();
        }}
      />
    </>
  );
};

export default GalleryManagement;
