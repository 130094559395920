import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import * as Yup from "yup";
import { useFormik } from "formik";
import EditIcon from "../../../Assets/Images/edit.png";
import Skeleton from "react-loading-skeleton";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import { toast } from "react-toastify";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";
import CustomThumbUpload from "../../../Components/CustomFileUpload/CustomThumbUpload";
import Constant from "../../../utills/Constant";
const AboutUs = () => {
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [selectedImagePrev, setSelectedImagePrev] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const getTermsAndCondition = async () => {
    try {
      const response = await AxiosInstance.get(ApiList.CONTENT_MANAGEMENT_GET, {
        params: {
          type: 3,
        },
      });
      if (response && response?.data?.s === 1) {
        formik.setFieldValue("AboutUS", response?.data?.r?.text);
        formik.setFieldValue("thumb", response?.data?.r?.thumb);
        if (response?.data?.r?.thumb) {
          setSelectedImagePrev(
            `${Constant.BASE_URL}${response?.data?.r?.thumb}`
          );
        }
        setIsLoading(false);
        setEditMode(true);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Failed to fetch terms and conditions. Please try again.");
      setIsLoading(false);
    }
  };
  const handleOnChangeImages = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.error("Invalid file type for thumb!");
    } else {
      const file = acceptedFiles[0];
      if (
        file &&
        ["image/jpeg", "image/png", "image/gif"].includes(file.type)
      ) {
        if (file.size <= 2 * 1024 * 1024) {
          const videoUrl = URL.createObjectURL(file);
          setSelectedImageFile(file);
          setSelectedImagePrev(videoUrl);
          formik.setFieldValue("thumb", file);
        } else {
          toast.error(`File size exceeds the limit of 2MB`);
        }
      } else {
        toast.error("Upload only (PNG,JPEG,WEBP,JPG)!");
      }
    }
  };
  useEffect(() => {
    getTermsAndCondition();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      AboutUS: "",
      thumb: null,
    },
    validationSchema: Yup.object({
      AboutUS: Yup.string().required("About Us is required"),
      thumb: Yup.string().required("cover image is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("text", values.AboutUS);
        formData.append("type", 3);
        if (selectedImageFile) {
          formData.append("thumb", values.thumb);
        }
        const res = await AxiosInstance.post(
          ApiList.CONTENT_MANAGEMENT_EDIT,
          formData
        );
        if (res && res.data?.s) {
          toast.success(res.data.m);
          resetForm();
          setIsLoading(false);
          getTermsAndCondition();
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
  });
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
          style={{
            position: "relative",
            backgroundColor: "#fff",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            padding: "40px 10px 10px 10px",
          }}
        >
          <Box sx={{ width: "600px", margin: "0 auto", marginBottom: "20px" }}>
            <CustomThumbUpload
              accept="image/*"
              onDrop={(acceptedFiles, rejectedFiles) =>
                handleOnChangeImages(acceptedFiles, rejectedFiles)
              }
              selectedFile={selectedImagePrev}
              onRemove={(e) => {
                e.stopPropagation();
                setSelectedImagePrev(null);
                setSelectedImageFile(null);
                formik.setFieldValue("thumb", null);
              }}
            />
            {formik.touched.thumb && formik.errors.thumb ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                }}
              >
                {formik.errors.thumb}
              </Typography>
            ) : null}
          </Box>
          {isEditMode && (
            <IconButton
              type="button"
              sx={{
                position: "absolute",
                top: "5px",
                right: "10px",
                width: "30px",
                height: "30px",
                backgroundColor: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#ffffff",
                },
                cursor: "pointer",
              }}
              onClick={() => setEditMode(false)}
            >
              <img src={EditIcon} alt="editIcon" />
            </IconButton>
          )}
          {isLoading ? (
            <Skeleton width={"100%"} height={"200px"} />
          ) : (
            <ReactQuill
              placeholder="Description"
              value={formik.values.AboutUS}
              ref={ref}
              readOnly={isEditMode}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [
                    { align: "right" },
                    { align: "center" },
                    { align: null },
                    { align: "justify" },
                  ],
                ],
              }}
              onChange={(content, delta, source, editor) => {
                content = content.replace(/(^<[^>]*>)([\s]*)/gm, "$1");
                formik.setFieldValue("AboutUS", content);
              }}
              className="termsCondtionContent"
            />
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginTop: "24px",
            }}
          >
            <CustomLoaderButton
              type="submit"
              variant="contained"
              fullWidth
              disabled={
                !(
                  formik.values.AboutUS.replace(/<[^>]+>/g, "").trim() &&
                  formik.values.thumb
                ) || isEditMode
              }
              btnTitle="Save"
              loading={isLoading}
              sx={{
                width: "120px",
                height: "48px",
              }}
            />
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AboutUs;
