import React, { useEffect, useState, useCallback } from "react";
import {
    Box,
    Dialog,
    IconButton,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid,
} from "@mui/material";
import moment from 'moment';
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import CloseIcon from "@mui/icons-material/Close";
import { ApiList } from "../../../Config/ApiList";
import AxiosInstance from "../../../Config/AxiosInstance";
import Constant from "../../../utills/Constant";
import CustomDateTimePicker from "../../../Components/CustomDatePicker/CustomDatePicker";
import CustomLoaderButton from '../../../Components/CustomLoaderButton/CustomLoaderButton';
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import CustomThumbUpload from "../../../Components/CustomFileUpload/CustomThumbUpload";
import dayjs from "dayjs";
const AddVideoModel = (props) => {
    const { open, onClose, close, onSubmit, typeCheck, editDetails } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedVideoPrev, setSelectedVideoPrev] = useState(null);
    const [selectedVideoFile, setSelectedVideoFile] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    console.log('categoryList', categoryList);
    
    // ==========GET_DETAILS==========
    useEffect(() => {
        if (typeCheck === 'Edit' && editDetails) {
            const category = categoryList.find(cat => cat?.name === editDetails?.name);
            formik.setValues({
                thumb: editDetails?.thumb || null,
                scheduleSelected: editDetails?.schedule_date !== null ? "2" : "1",
                selectCategory: category?.id || 0,
                title: editDetails?.title || "",
                link: editDetails?.link || "",
                timeSelected: editDetails?.schedule_date ? dayjs(editDetails?.schedule_date) : null,
            });
            if (editDetails.thumb) {
                setSelectedVideoPrev(editDetails?.thumb ? `${Constant.BASE_URL}${editDetails.thumb}` : null);
            }
        } else {
            formik.resetForm();
            setSelectedVideoFile(null);
            setSelectedVideoPrev(null);
        }
    }, [editDetails, typeCheck, open, categoryList]);

    // ==========GET_CATEGORY==========
    useEffect(() => {
        const fetchCategoryList = async () => {
            try {
                const response = await AxiosInstance.get(ApiList.GET_CATEGORY, {
                    params: {
                        type: 2
                    }
                });
                if (response && response?.data?.s) {
                    setCategoryList(response?.data?.r);
                } else {
                    setCategoryList([]);
                }
            } catch (error) {
                console.log('error');
            }
        }
        fetchCategoryList();
    }, []);

    // ==========UPLOAD_THUMB==========
    const handleThumbChange = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error("Invalid file type for thumb!");
        } else {
            const file = acceptedFiles[0];
            if (file && ["image/jpeg", "image/png", "image/webp"].includes(file.type)) {
                if (file.size <= 2 * 1024 * 1024) {
                    const videoUrl = URL.createObjectURL(file);
                    setSelectedVideoFile(file);
                    setSelectedVideoPrev(videoUrl);
                    formik.setFieldValue('thumb', file);
                } else {
                    toast.error(`File size exceeds the limit of 2MB`);
                }
            } else {
                toast.error("Invalid thumb file type!");
            }
        }
    }, []);

    // ==========FORM_VALIDATION==========
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            thumb: null,
            scheduleSelected: "1",
            selectCategory: 0,
            title: "",
            link: "",
            timeSelected: null
        },
        validationSchema: Yup.object().shape({
            thumb: Yup.string().required("Thumb is required"),
            selectCategory: Yup.mixed()
                .test(
                    "is-not-zero",
                    "Category name is required!",
                    (value) => value !== 0
                )
                .required("SelectCategory is required!"),
            title: Yup.string().required("Title is required"),
            link: Yup.string()
                .required("Link is required")
                .matches(Constant.REGEX.WEBSITEURL, "Please Provide a valid link"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            if (isLoading) {
                return;
            } else {
                try {
                    const formData = new FormData();
                    formData.append('title', values.title);
                    formData.append('link', values.link);
                    if (selectedVideoFile) {
                        formData.append('thumb', selectedVideoFile);
                    }
                    if (typeCheck === 'Edit') {
                        formData.append('id', editDetails?.id)
                    }
                    formData.append('cat_id', values.selectCategory);
                    if (values.scheduleSelected === "2") {
                        formData.append('schedule_date', moment(values.timeSelected?.$d).utc().format('YYYY-MM-DD HH:mm:ss'));
                    }
                    const apiEndpoint = typeCheck === 'Edit' ? ApiList.EDIT_VIDEO : ApiList.ADD_VIDEO;
                    const response = await AxiosInstance.post(apiEndpoint, formData);
                    
                    if (response && response.data.s) {
                        toast.success(response.data.m);
                        resetForm();
                        setSelectedVideoFile(null);
                        setSelectedVideoPrev(null);
                        setIsLoading(false);
                        onSubmit();
                    }
                } catch (error) {
                    console.error('Error:', error);
                    setIsLoading(false);
                }
            }
        },
    });
    console.log('formik', formik.values);
    
    return (
        <Dialog 
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "40px",
                    padding: '50px 50px 0px 50px',
                    height: "575px",
                    overflow: "scroll",
                    "::-webkit-scrollbar" : {width:"0px",height:'0px'}
                },
                "& .MuiDialogContent-root": {
                    overflowY: "initial",
                },
            }}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    return false;
                }}
            >
                <Box className="modal"
                    sx={{ width: "100%", justifyContent: "center", display: "flex", justifyContent: 'space-between', marginBottom: '30px'  }}
                >
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ fontSize: "32px", fontWeight: "500" }}>
                        {typeCheck ? "Edit Details" : "Add Details"}
                    </Typography>
                    <Typography></Typography>
                </Box>
                <Grid container spacing={3} >
                    <Grid item xs={12}>
                        <CustomThumbUpload
                            accept="image/*"
                            onDrop={handleThumbChange}
                            selectedFile={selectedVideoPrev}
                            onRemove={(e) => {
                                e.stopPropagation();
                                setSelectedVideoPrev(null);
                                setSelectedVideoFile(null);
                                formik.setFieldValue('thumb', null)
                            }}
                            typeCheck={typeCheck}
                            isLoading={isLoading}
                        />
                        {formik.touched.thumb && formik.errors.thumb ? (
                            <Typography
                                sx={{
                                    color: "red",
                                    fontWeight: "400",
                                    fontSize: { xs: "14px", sm: "14px" },
                                    marginTop: "5px",
                                }}
                            >
                                {formik.errors.thumb}
                            </Typography>
                        ) : null}
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                            <RadioGroup
                                value={formik.values.scheduleSelected}
                                name="scheduleSelected"
                                onChange={formik.handleChange}
                                sx={{ width: '100%' }}
                            >
                                {isLoading ? <Skeleton height={'50px'} /> : (
                                    <Box sx={{ display: "flex", width: "100%", alignItems: 'center', justifyContent: 'center' }}>
                                        <FormControlLabel
                                            value={"1"}
                                            control={<Radio />}
                                            label="Publish Now"
                                        />
                                        <FormControlLabel
                                            value={"2"}
                                            control={<Radio />}
                                            label="Schedule Later"
                                        />
                                    </Box>
                                )}
                            </RadioGroup>
                        </Box>
                    </Grid>
                    {formik.values.scheduleSelected === "2" && (
                        <Grid item xs={12}>
                            <CustomDateTimePicker
                                value={formik.values.timeSelected}
                                onChange={(newValue) => formik.setFieldValue('timeSelected', newValue)}
                                error={formik.touched.timeSelected && formik.errors.timeSelected}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <CustomDropDown
                            Title={"Select Category"}
                            value={formik.values.selectCategory}
                            onChange={formik.handleChange}
                            placeholder={'Select Category'}
                            menuList={categoryList}
                            NoData='No Category Available'
                            isLoading={isLoading}
                            errors={formik.touched.selectCategory && formik.errors.selectCategory}
                            disabled={typeCheck === "View"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextField
                            label="Add title"
                            type="text"
                            placeholder="Title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.touched.title && formik.errors.title}
                            onKeyUp={formik.handleBlur}
                            disabled={typeCheck === "View"}
                            sx={{
                                "& .MuiInputBase-root": {
                                    background: "#F0F0F0",
                                    borderRadius: "10px",
                                },
                            }}
                            isLoading={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextField
                            label="Add Link"
                            type="text"
                            placeholder="Link"
                            name="link"
                            value={formik.values.link}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyUp={formik.handleBlur}
                            errors={formik.touched.link && formik.errors.link}
                            disabled={typeCheck === "View"}
                            sx={{
                                "& .MuiInputBase-root": {
                                    background: "#F0F0F0",
                                    borderRadius: "10px",
                                },
                            }}
                            isLoading={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ py: 3, px: 4, display: 'flex', width: "100%", justifyContent: "center" }}>
                            <CustomLoaderButton btnTitle={typeCheck ? "Update" : "Add"} sx={{ width: "150px" }} type='submit' loading={isLoading} />
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Dialog>
    );
};

export default AddVideoModel;
